@use "color";
@use "button";
@use "text";

.publishedContent {
  border-bottom-width: 2px;
  border-bottom-color: color.$coseeGreen;
  border-bottom-style: solid;
  padding: 20px;
  margin: 20px;
}
