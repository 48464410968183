@use "color";
@use "generalDefinitions";
@use "text";

.title {
  color: color.$coseeGreen;
  background-color: color.$coseeGrey;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: generalDefinitions.$applicationBorder;
  padding-right: generalDefinitions.$applicationBorder;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: text.$mainFont;
  font-size: 26px;
  font-weight: 700;
}

.logo {
  float: right;
  height: 36px;
}
