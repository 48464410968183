$background: rgb(233, 233, 233);
$backgroundCurrentTab: white;
$coseeGreen: rgb(212, 216, 0);
$coseeGrey: rgb(38, 38, 38);
$coseeLightGreen: rgb(233, 233, 233);

$darkGrey: #262626;
$lightGreen: #d4d800;
$darkGreen: #1d7f3f;
$green: #4faf47;
$white: #ffffff;

$orange: #fbb900;
$lightBlue: #70caf3;
$petrolBlue: #005685;
