@use "button";
@use "color";
@use "text";

.filterButton {
  @include button.buttonThemeInverse;
  &:not(.active) {
    background-color: color.$backgroundCurrentTab;
    color: color.$coseeGrey;
  }
  &.active {
    background-color: color.$coseeGrey;
    color: color.$backgroundCurrentTab;
    cursor: pointer;
  }

  font-size: x-small;
  font-weight: normal;
  padding: 5px;
  border-width: 1px;
  margin-left: 8px;
  margin-top: 8px;
}

.filterBox {
  float: right;
  flex-direction: column;
}

.inputField {
  font-size: small;
  margin-left: 5px;
  margin-bottom: 10px;
  width: 90%;
  border: none;
}
.searchIcon {
  margin-left: 5px;
  margin-bottom: 3px;
  margin-top: 4px;
  font-size: medium;
  float: left;
}

.buttonRow {
  border-top-width: 2px;
  border-top-color: color.$coseeGrey;
  border-top-style: solid;
  padding-top: 2px;
  margin-top: 0;
  flex-direction: row;
}

.textRow {
  border-width: 5px;
}

.contentOverview {
  padding-bottom: 10px;
}
