@use "color";
@use "text";

@mixin buttonTheme() {
  background-color: color.$coseeGrey;
  color: color.$backgroundCurrentTab;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: small;
  font-family: text.$mainFont;
  span#left {
    margin-left: 10px;
  }
  span#right {
    margin-right: 10px;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin buttonThemeInverse() {
  background-color: color.$backgroundCurrentTab;
  color: color.$coseeGrey;
  border-color: color.$coseeGrey;
  border-style: solid;
  border-width: 2px;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: bold;
  font-size: small;
  font-family: text.$mainFont;
  span#left {
    margin-left: 10px;
  }
  span#right {
    margin-right: 10px;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.5;
  }
}
