@use "color";
@use "button";
@use "generalDefinitions";

@mixin currentTabTheme() {
  background-color: color.$backgroundCurrentTab;
  padding-left: generalDefinitions.$applicationBorder;
  padding-right: generalDefinitions.$applicationBorder;
  margin-left: generalDefinitions.$applicationBorder;
  margin-right: generalDefinitions.$applicationBorder;
  margin-top: 30px;
}

.currentTab {
  @include currentTabTheme;
}
