@use "color";
@use "button";
@use "text";
@use "currentTab";

.window {
  @include currentTab.currentTabTheme;
  text-align: center;
  padding-bottom: 20px;
}

.loginButton {
  @include button.buttonTheme;
  background-color: color.$green;
}
