@use "color";
@use "text";
@use "button";

.icon {
  font-size: x-large;
  &.draft {
    color: #fbb900;
  }
  &.published {
    color: green;
  }
  &.rework {
    color: #c70000;
  }
  &.archived {
    color: black;
  }
  &.unknown {
    color: rebeccapurple;
  }
}

.functionalBar {
  &.left {
    padding-right: 5px;
  }
  &.right {
    border-left-width: 3px;
    border-color: color.$coseeGreen;
    border-left-style: solid;
    padding-left: 5px;
  }
}

.functionalBox {
  display: flex;
  float: right;
  margin-right: 0;
}

.functionalIcon {
  @include button.buttonThemeInverse;
  color: color.$coseeGrey;
  border: none;
  font-size: medium;
  &.inactive {
    color: #b2b2b2;
  }
}

.light {
  color: grey;
}

table {
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-collapse: collapse;
}

td {
  padding: 20px;
  margin: 20px;
}

tr:not(:last-of-type) > td {
  border-bottom-width: 2px;
  border-bottom-color: color.$coseeGreen;
  border-bottom-style: solid;
}
