.noBorder {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.right {
  margin-top: 0px;
  padding-top: 0px;
}
.status {
  padding-bottom: 10px;
  padding-top: 10px;
}
.row {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.contentDetail {
  padding-bottom: 25px;
}
