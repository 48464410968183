@use "color";
@use "button";
@use "generalDefinitions";

.menu {
  list-style-type: none;
  overflow: hidden;
  background-color: color.$coseeGrey;
  padding-left: generalDefinitions.$applicationBorder;
  padding-right: generalDefinitions.$applicationBorder;
}

.menuButton {
  @include button.buttonTheme;
  text-decoration: none;
  float: left;
  &.active {
    background-color: color.$background;
    color: color.$coseeGrey;
    border-top-color: color.$coseeGreen;
    border-top-style: solid;
    border-top-width: 2px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    text-transform: uppercase;
    padding: 10px 20px;
  }
}
