@use "color";
@use "button";
@use "text";

.uploadButton {
  @include button.buttonTheme;
  margin-top: 20px;
  margin-bottom: 20px;
}

.uploadButtonInverse {
  @include button.buttonThemeInverse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.submitButton {
  @include button.buttonTheme;
  background-color: color.$coseeGreen;
  color: color.$coseeGrey;
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: color.$coseeGreen;
  border-style: solid;
  border-width: 2px;
}

.submitButtonInverse {
  @include button.buttonThemeInverse;
  border-color: color.$coseeGreen;
  color: color.$coseeGrey;
  margin-top: 20px;
  margin-bottom: 20px;
}

.newUpload {
  padding-bottom: 25px;
}

@mixin boxTheme {
  border-color: color.$coseeGreen;
  border-style: solid;
  border-width: 2px;
  margin-right: 20px;
  margin-top: 20px;
  width: 100%;
  min-width: 420px;
}

.uploadBox {
  @include boxTheme;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.upload__image-wrapper {
  @include boxTheme;
}

.upload_static-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.image-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;
  width: 400px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 10px;
}

.deleteAndImage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;
  margin: 10px;
  padding-right: 59px;
}

.image-name {
  word-wrap: break-word;
  font-size: medium;
  font-family: "swap", sans-serif;
  text-align: center;
  width: 370px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 50%;
}

.processUpload {
  padding-top: 10px;
  padding-bottom: 10px;
}
