@use "color";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

$mainFont: "Source Sans Pro", sans-serif;

h2.currentText {
  font-family: $mainFont;
  font-size: x-large;
  padding-top: 20px;
}

p.currentText {
  font-family: $mainFont;
  font-size: medium;
  padding-top: 10px;
}

.jsonName {
  font-family: $mainFont;
  font-size: large;
  text-decoration: none;
  color: black;
}

.contentCell {
  font-family: $mainFont;
  font-size: medium;
}

.contentType {
  text-transform: uppercase;
}

p.error {
  color: red;
  padding-bottom: 50px;
}
